import { TClientBody } from 'core/models/clientLBS'
import DefaultModal from '../defaultModal/defaultModal'
import VisibilityIcon from '@mui/icons-material/Visibility'
import {
    capitalizeFirstLetter,
    formatCurrencyBR,
    formatDateBr,
    formatDocument,
    formatPhoneNumber,
    formatRG,
    formatarCEP,
    formatBigName
} from 'core/utils/globalFunctions'
import {
    Box,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { ClientLbsService } from 'core/api/client/clientService'
import { useEffect, useState } from 'react'
import { Order } from 'core/models/table'
import DataTablePagination from 'app/components/table/pagination/pagination'
import {
    InfoKey,
    InfoRowContainer,
    InfoTitle,
    InfoValue,
    Section,
    SectionBloc,
    SectionCol
} from 'core/theme/globalStyles'
import Spinner from 'app/components/spinner/spinner'
import theme from 'core/theme/theme'

interface IClientDetailsProps {
    isOpen: boolean
    onClose: () => void
    onOpen: () => void
    client: TClientBody
}

const ClientDetailsModal = (props: IClientDetailsProps) => {
    const { isOpen, onClose, onOpen, client } = props

    const [tempSaleid, setTempSaleid] = useState<number | undefined>()
    const [showSalesItems, setShowSalesItems] = useState(false)

    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [page, setPage] = useState(0)
    const [count, setCount] = useState(0)
    const [order, setOrder] = useState<Order>('asc')
    const [orderBy, setOrderBy] = useState('name')

    const [itemsRowsPerPage, setItemsRowsPerPage] = useState(10)
    const [itemsPage, setItemsPage] = useState(0)
    const [itemsCount, setItemsCount] = useState(0)

    // ? requisicao para as compras do cliente
    const clientSales = useQuery({
        queryKey: ['clientSales', client.id],
        queryFn: () =>
            ClientLbsService.getClientSales(
                client.id,
                page,
                rowsPerPage,
                client.cpforcnpj
            ),
        staleTime: Infinity
    })

    // ? requisição para os itens de cada compra do cliente, condicional à existência de tempSaleid
    const clientSalesItems = useQuery({
        queryKey: ['clientSalesItems', tempSaleid],
        queryFn: () =>
            tempSaleid
                ? ClientLbsService.getClientSalesItems(
                      tempSaleid,
                      itemsPage,
                      itemsRowsPerPage
                  )
                : Promise.resolve(null),
        staleTime: Infinity,
        enabled: !!tempSaleid
    })

    // ? bloco a ser montado
    const clientPersonalInfos: IInfoProps[] = [
        { key: 'Nome', value: client.name, formater: formatBigName },
        { key: 'RG', value: client.rg, formater: formatRG },
        {
            key: 'CPF / CNPJ',
            value: client.cpforcnpj,
            formater: formatDocument
        },
        {
            key: 'Data de nascimento',
            value: client.birthdate,
            formater: formatDateBr
        },
        { key: 'Criado em', value: client.createdAt, formater: formatDateBr },
        {
            key: 'Sexo',
            value: client.sexo.name,
            formater: capitalizeFirstLetter
        },
        {
            key: 'Estado civil',
            value: client.maritalStatus,
            formater: capitalizeFirstLetter
        },
        {
            key: 'Ativo',
            value: client.active ? (
                <Box
                    sx={{
                        width: 14,
                        height: 14,
                        borderRadius: '50%',
                        backgroundColor: theme.COLORS.GREEN4,
                        marginTop: '4px'
                    }}
                ></Box>
            ) : (
                <Box
                    sx={{
                        width: 14,
                        height: 14,
                        borderRadius: '50%',
                        backgroundColor: theme.COLORS.RED,
                        marginTop: '4px'
                    }}
                ></Box>
            )
        }
    ]

    const clientAddressInfos: IInfoProps[] = [
        { key: 'CEP', value: client.address.zipCode, formater: formatarCEP },
        { key: 'Estado', value: client.address.uf },
        { key: 'Cidade', value: client.address.city },
        { key: 'Bairro', value: client.address.neighborhood },
        { key: 'Rua', value: client.address.road },
        { key: 'Número', value: client.address.number }
    ]

    const clientContactsInfos: IInfoProps[] = [
        {
            key: 'Celular principal',
            value: client.contacts.cellPhone,
            formater: formatPhoneNumber
        },
        {
            key: 'Celular secundário',
            value: client.contacts.cellPhone2,
            formater: formatPhoneNumber
        },
        {
            key: 'Telefone',
            value: client.contacts.telephone,
            formater: formatPhoneNumber
        },
        { key: 'Email', value: client.contacts.email }
    ]

    useEffect(() => {
        if (clientSales.isSuccess && clientSales.data) {
            setCount(clientSales.data.totalElements)
        }
        if (clientSalesItems.isSuccess && clientSalesItems.data) {
            setItemsCount(clientSalesItems.data.totalElements)
        }
    }, [
        clientSales.isSuccess,
        clientSales.data,
        clientSalesItems.isSuccess,
        clientSalesItems.data
    ])

    return (
        <>
            <DefaultModal
                title="Detalhes do cliente"
                isOpen={isOpen}
                onClose={onClose}
                onOpen={onOpen}
                children={
                    <SectionCol>
                        <InfosComponent
                            items={[
                                {
                                    title: 'Informações gerais',
                                    infos: clientPersonalInfos
                                },
                                {
                                    title: 'Endereço',
                                    infos: clientAddressInfos
                                },
                                {
                                    title: 'Contatos',
                                    infos: clientContactsInfos
                                }
                            ]}
                        />
                        {clientSales.data?.content && (
                            <TableContainer
                                sx={{ maxHeight: 300, position: 'relative' }}
                            >
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Vendedor</TableCell>
                                            <TableCell align="right">
                                                Pagamento
                                            </TableCell>
                                            <TableCell align="right">
                                                Status
                                            </TableCell>
                                            <TableCell align="right">
                                                Código
                                            </TableCell>
                                            <TableCell align="right">
                                                Tipo
                                            </TableCell>
                                            <TableCell align="right">
                                                Data
                                            </TableCell>
                                            <TableCell align="right">
                                                Items
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {clientSales.data?.content.length ===
                                        0 ? (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={7}
                                                    align="center"
                                                >
                                                    Nenhuma venda encontrada
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            clientSales.data?.content.map(
                                                (row) => (
                                                    <TableRow key={row.saleId}>
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                        >
                                                            {
                                                                row.nameCollaborator
                                                            }
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {row.paymentMethod}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {row.status}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {row.saleCod}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {row.type}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {formatDateBr(
                                                                row.data
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <IconButton
                                                                onClick={() => {
                                                                    setShowSalesItems(
                                                                        true
                                                                    )
                                                                    setTempSaleid(
                                                                        row.saleId
                                                                    )
                                                                }}
                                                            >
                                                                <VisibilityIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                        <DataTablePagination
                            setPage={setPage}
                            page={page}
                            setRowsPerPage={setRowsPerPage}
                            rowsPerPage={rowsPerPage}
                            count={count}
                        />
                    </SectionCol>
                }
            />
            <DefaultModal
                title="Items da venda"
                isOpen={showSalesItems}
                onClose={() => setShowSalesItems(false)}
                onOpen={() => setShowSalesItems(true)}
            >
                <TableContainer
                    sx={{
                        minHeight: 300,
                        position: 'relative',
                        maxHeight: 350
                    }}
                >
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell align="left">Nome</TableCell>
                                <TableCell align="left">Descrição</TableCell>
                                <TableCell align="right">Qtd</TableCell>
                                <TableCell align="right">Valor Unit.</TableCell>
                                <TableCell align="right">Valor total</TableCell>
                            </TableRow>
                        </TableHead>
                        {clientSalesItems.isLoading ? (
                            <Spinner
                                state={clientSales.isLoading}
                                size={10}
                                css={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%'
                                }}
                            />
                        ) : (
                            <TableBody>
                                {clientSalesItems.data?.content.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">
                                            Nenhum item encontrado
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    clientSalesItems.data?.content.map(
                                        (row) => (
                                            <TableRow key={row.productId}>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {row.productId}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {row.productName}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {
                                                        row.characteristicsDescription
                                                    }
                                                </TableCell>
                                                <TableCell align="right">
                                                    {row.itemSalesAmount}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {formatCurrencyBR(
                                                        row.unitaryValue
                                                    )}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {formatCurrencyBR(
                                                        row.itemSalesAmount *
                                                            row.unitaryValue
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    )
                                )}
                            </TableBody>
                        )}
                    </Table>
                    <Box
                        sx={{ position: 'absolute', bottom: 0, width: '100%' }}
                    >
                        <DataTablePagination
                            setPage={setItemsPage}
                            page={itemsPage}
                            setRowsPerPage={setItemsRowsPerPage}
                            rowsPerPage={itemsRowsPerPage}
                            count={itemsCount}
                        />
                    </Box>
                </TableContainer>
            </DefaultModal>
        </>
    )
}

interface IInfosComponentProps {
    items: IBlockOfInfosProps[]
    style?: React.CSSProperties | undefined
}

export const InfosComponent = (props: IInfosComponentProps) => {
    const { items, style } = props

    return (
        <Section style={style}>
            {items.map((infos) => (
                <SectionBloc>
                    <BlockOfInfos
                        key={infos.title}
                        title={infos.title}
                        infos={infos.infos}
                    />
                </SectionBloc>
            ))}
        </Section>
    )
}

export interface IInfoProps {
    key: string
    value: any
    formater?: (value: any) => any
}

interface IBlockOfInfosProps {
    title: string
    infos: IInfoProps[]
}

// ? bloco de informações

const BlockOfInfos = (props: IBlockOfInfosProps) => {
    const { title, infos } = props

    return (
        <SectionCol>
            <div
                style={{
                    backgroundColor: theme.COLORS.YELLOW2,
                    padding: '0.5rem'
                }}
            >
                <InfoTitle>{title}</InfoTitle>
            </div>
            <div
                style={{
                    padding: '0.5rem'
                }}
            >
                {infos.map((info) => (
                    <InfoRow
                        key={info.key}
                        keyValue={info.key}
                        value={info.value}
                        formater={info.formater}
                    />
                ))}
            </div>
        </SectionCol>
    )
}

// ? linha de informação

interface IInfoRowProps {
    keyValue: string
    value: any
    formater?: (value: any) => any
}

const InfoRow = (props: IInfoRowProps) => {
    const { keyValue, value, formater } = props

    return (
        <InfoRowContainer>
            <InfoKey>{keyValue}</InfoKey>
            <Tooltip
                title={
                    keyValue === 'Email' ? value : keyValue === 'Nome' ? value : ''
                }
            >
                <InfoValue>{formater ? formater(value) : value}</InfoValue>
            </Tooltip>
        </InfoRowContainer>
    )
}

export default ClientDetailsModal
