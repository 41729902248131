import {
    Box,
    Button,
    CircularProgress,
    FormHelperText,
    TextField,
    Typography
} from '@mui/material'
import DefaultModal from '../defaultModal/defaultModal'
import React, {
    ChangeEvent,
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react'
import {
    TCharacteristicsDTO,
    TCharacteristicsEditDTO,
    TProductBody,
    TProductEditer,
    TProductEditImage,
    TProductRegister,
    TProductTDO
} from 'core/models/product'
import { TCategoryBody } from 'core/models/category'
import { TBrandBody } from 'core/models/brand'
import { TCollectionBody } from 'core/models/collection'
import { useFormik } from 'formik'
import PriceTextField from 'app/components/InputPrice/PriceTextField'
import GenericTextField from 'app/components/genericTextField/GenericTextField'
import AsyncAutoComplete from 'app/components/assyncronusAutoComplete/AssyncAutoComplete'
import {
    BRAND_LIST,
    CATEGORY_LIST,
    CHARACTERISTIC_LIST,
    COLLECTION_LIST,
    SUPPLIER_LIST
} from 'core/utils/constants'
import { TSupplierBody } from 'core/models/supplier'
import {
    calcularCustoEtiqueta,
    calcularLucroRevenda,
    deepEqualProduct
} from 'core/utils/globalFunctions'
import {
    AddInfoContainer,
    InfosContainer,
    InputsPrice,
    LeftContent,
    MainContainer,
    PriceContainer,
    ProfitInPercentage,
    RightContent,
    RowAlign
} from './styles'
import CharacteristicList from 'app/components/characteristicList/characteristicList'
import DefaultDialog from '../../defaultDialog/defaultDialog'
import { Validations } from 'core/utils/validations'
import { ProductService } from 'core/api/product/productService'
import { Notification } from 'app/components/toastNotification/toastNotification'
import { AxiosError } from 'axios'
import ProductImages from 'app/components/uploadImage/productImages'
import EditCharacteristics from '../editCharacteristic/editCharacteristics'
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query'
import { IPage } from 'core/models/utils'
import { ImageService } from 'core/api/images/imageService'
import { v4 as uuidv4 } from 'uuid'

interface IProductModalProps {
    isOpen: boolean
    onClose: () => void
    onOpen: () => void
    product: TProductBody
    refetch: (
        options?: RefetchOptions
    ) => Promise<QueryObserverResult<IPage<TProductBody | undefined>, Error>>
}

const EditProductModal = (props: IProductModalProps) => {
    const { isOpen, onClose, onOpen, product, refetch } = props
    const [loadingImages, setLoadingImages] = useState(false)
    const [editCaracteristic, setEditCaracteristic] = useState<
        TCharacteristicsDTO | undefined
    >()
    const [editCaracteristicModal, setEditCaracteristicModal] = useState(false)
    const [removedCharacteristic, setRemovedCharacteristic] = useState<
        number[] | undefined
    >()

    // estados para guardar os valores iniciais do produto
    const [category, setCategory] = useState<TCategoryBody | undefined>(product.category)
    const [brand, setBrand] = useState<TBrandBody | undefined>(product.brand)
    const [collection, setCollection] = useState<TCollectionBody | undefined>(
        product.collection
    )
    const [suppiler, setSuppiler] = useState<TSupplierBody | undefined>(product.suppiler)

    // Estado para armazenar as imagens carregadas inicialmente
    const [editedImages, setEditedImages] = useState<TProductEditImage>({
        imageOne: { id: undefined, url: undefined, changed: 'NAO_ALTERADA' },
        imageTwo: { id: undefined, url: undefined, changed: 'NAO_ALTERADA' },
        imageThree: { id: undefined, url: undefined, changed: 'NAO_ALTERADA' }
    })

    const [initialImages, setInitialImages] = useState<TProductEditImage>({
        imageOne: { id: undefined, url: undefined, changed: 'NAO_ALTERADA' },
        imageTwo: { id: undefined, url: undefined, changed: 'NAO_ALTERADA' },
        imageThree: { id: undefined, url: undefined, changed: 'NAO_ALTERADA' }
    })

    // Funçao para guargar as caracteristicas iniciaisdo produto
    function transformProductCharacteristics(
        productCharacteristics: any[]
    ): TCharacteristicsDTO[] {
        return productCharacteristics.map((char) => ({
            id: char.id,
            characteristcId: char.characteristics.id,
            name: char.characteristics.name,
            amount: char.amount,
            description: char.description
        }))
    }

    // Usando a funçao pra guardar as caracteristicas iniciais do produto em uma variavel
    const characteristicsDTOList = transformProductCharacteristics(
        product.productCharacteristics
    )

    // ? carrega as imagens
    useEffect(() => {
        const fetchImages = async () => {
            if (isOpen) {
                setLoadingImages(true)
                try {
                    // Mapeie as imagens para obter tanto o id quanto a URL
                    const imagePromises = product.images.map((image) =>
                        ProductService.getImage(image.id).then((url) => ({
                            id: image.id,
                            url: url || '',
                            changed: 'NAO_ALTERADA' as 'NAO_ALTERADA'
                        }))
                    )
                    const imageResponses = await Promise.all(imagePromises)

                    // Construa o objeto newImages com id e url
                    const newImages: TProductEditImage = {
                        imageOne: imageResponses[0] || {
                            id: undefined,
                            url: '',
                            changed: 'NAO_ALTERADA'
                        },
                        imageTwo: imageResponses[1] || {
                            id: undefined,
                            url: '',
                            changed: 'NAO_ALTERADA'
                        },
                        imageThree: imageResponses[2] || {
                            id: undefined,
                            url: '',
                            changed: 'NAO_ALTERADA'
                        }
                    }

                    setEditedImages(newImages)
                    setInitialImages(newImages)

                    // Extraia apenas as URLs para salvar no formik
                    const formikImages = {
                        imageOne: newImages.imageOne.url,
                        imageTwo: newImages.imageTwo.url,
                        imageThree: newImages.imageThree.url
                    }

                    formik.setFieldValue('productImages', formikImages)
                } catch (error) {
                    console.error('Error fetching images', error)
                } finally {
                    setLoadingImages(false)
                }
            }
        }
        fetchImages()
    }, [isOpen])

    const calcularPercentualLucro = (costPrice: number | undefined, sellingPrice: number): number => {
        if (costPrice) {
            const lucroPercentual = ((sellingPrice - costPrice) / costPrice) * 100;
            return lucroPercentual < 0 ? 0 : Number(lucroPercentual.toFixed(2));
        }
        return 0;
    };


    const initialValues: TProductRegister = {
        name: product.name,
        sku: product.sku,
        amount: product.amount,
        priceCost: product.priceCost,
        lucroRevenda: calcularPercentualLucro(product.priceCost, product.resalePrice),
        lucroEtiqueta: calcularPercentualLucro(product.resalePrice, product.priceTag),
        priceTag: product.priceTag,
        resalePrice: product.resalePrice,
        categoryId: Number(product.category.id),
        brandId: Number(product.brand.id),
        collectionId: Number(product.collection.id),
        supplierId: Number(product.suppiler.id),
        characteristicsDTOList: characteristicsDTOList,
        productImages: { imageOne: '', imageTwo: '', imageThree: '' }
    }
    const initialCharacteristics: TCharacteristicsDTO = {
        name: '',
        amount: 0,
        description: ''
    }


    // estados para guardar os valores iniciais dos preços e seta-los quando forem alterado
    const [priceCost, setPriceCost] = useState<number | undefined>(
        product.priceCost
    )
    const [priceTag, setPriceTag] = useState<number | undefined>(
        product.priceTag
    )
    const [resalePrice, setResalePrice] = useState<number | undefined>(
        product.resalePrice
    )

    const handleResetStates = () => {
        setIsLoading(false)
        formik.resetForm()
    }

    const callEditProduct = async (newProduct: TProductRegister) => {
        // Padrão para validar UUID v4
        const uuidv4Pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

        const productCharacteristics: TCharacteristicsEditDTO[] = newProduct.characteristicsDTOList.map((characteristic) => {
            const isUUIDv4 = uuidv4Pattern.test(characteristic.id);
            return {
                productCharacteristicsId: isUUIDv4 ? null : characteristic.id,
                caracteristicsId: characteristic.characteristcId,
                amount: characteristic.amount,
                description: characteristic.description
            };
        });

        let cleanedProduct: any = {
            name: newProduct.name,
            amountProduct: formik.values.characteristicsDTOList.length > 0 ?
                undefined : Number(newProduct.amount),
            priceCost: priceCost ?? 0,
            resalePrice: resalePrice ?? 0,
            priceTag: priceTag ?? 0,
            brandId: Number(brand?.id) ?? 0,
            categoryId: Number(category?.id) ?? 0,
            collectionId: collection?.id ?? 0,
            supplierId: suppiler?.id ?? 0,
            productCharacteristics: productCharacteristics
        };

        let productTDO: any = {
            productDTO: cleanedProduct
        };

        setIsLoading(true);

        const processImages = async () => {
            const formikImages = formik.values.productImages;

            if (formikImages) {  // Verificação se formikImages não é undefined
                const imagePromises = Object.entries(formikImages).map(
                    async ([key, formikImageUrl]) => {
                        const initialImage = initialImages[key as keyof TProductEditImage];

                        // Excluir imagem
                        if (!formikImageUrl && initialImage.url) {
                            if (initialImage.id) {
                                await ImageService.deleteImage(initialImage.id);
                            }
                        }

                        // Atualizar imagem
                        if (formikImageUrl && formikImageUrl !== initialImage.url) {
                            if (initialImage.id) {
                                await ImageService.updateImage(formikImageUrl, initialImage.id);
                            }
                        }

                        // Adicionar imagem
                        if (initialImage.url === '' && formikImageUrl) {
                            await ImageService.createImage(formikImageUrl, String(product.id));
                        }
                    }
                );

                await Promise.all(imagePromises);
            } else {
                console.error('formikImages está undefined');
            }
        };

        const processCharacteristics = async () => {
            if (removedCharacteristic && removedCharacteristic.length > 0) {
                const characteristicPromises = removedCharacteristic.map(async (id) => {
                    await ProductService.deleteProductCharacteristics(String(id));
                });
                await Promise.all(characteristicPromises);
            }
        };

        try {
            await processImages();
            await processCharacteristics();

            ProductService.updateProduct(productTDO, String(product.id))
                .then((resp) => {
                    handleResetStates();
                    setIsLoading(false);
                    onClose();
                    refetch();
                })
                .catch((err: AxiosError) => {
                    Notification(String(err.message), 'error');
                    setIsLoading(false);
                });
        } catch (error) {
            Notification(String(error), 'error');
            setIsLoading(false);
        }

    };

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validateOnBlur: false,
        validationSchema: Validations.ProductRegisterSchema,
        validateOnChange: false,
        onSubmit: async (values, { setSubmitting }) => {
            setEditedImages({
                imageOne: { ...editedImages.imageOne, url: formik.values.productImages?.imageOne },
                imageTwo: { ...editedImages.imageTwo, url: formik.values.productImages?.imageTwo },
                imageThree: { ...editedImages.imageThree, url: formik.values.productImages?.imageThree }
            });
            callEditProduct(values)
        }
    })

    const totalCharacteristics = useMemo(() => {
        const totalAmountCharacteristics = formik.values.characteristicsDTOList
            .map((item) => item.amount)
            .reduce((acc, curr) => acc + curr, 0)
        return totalAmountCharacteristics;
    },
        [formik.values.characteristicsDTOList]);

    const [isLoading, setIsLoading] = useState(false)
    const [isValidating, setValidating] = useState(false)

    // ? adicionar uma caracteristica
    const [charDialog, setCharDialog] = useState(false)
    const [tempCharacteristic, setTempCharacteristic] =
        useState<TCharacteristicsDTO>(initialCharacteristics)
    const [errorDialog, setErrorDialog] = useState('')

    const [selectedImage, setSelectedImage] = useState<
        string | ArrayBuffer | null
    >(null)
    const [priceTagPercent, setPriceTagPercent] = useState<string>()
    const [priceRelasePercent, setPriceRelasePercent] = useState<string>()

    const removeCharacteristic = (rmvItemId: string) => {
        setRemovedCharacteristic([
            ...(removedCharacteristic ?? []),
            parseInt(rmvItemId)
        ])

        formik.setFieldValue(
            'characteristicsDTOList',
            formik.values.characteristicsDTOList.filter(
                (item) => item.id !== rmvItemId
            )
        )
    }

    const handleEditCharacteristic = (id: string) => {
        const characteristic = formik.values.characteristicsDTOList.find(
            (item) => item.id === id
        )
        setEditCaracteristic(characteristic)
        setEditCaracteristicModal(true)
    }

    const handleSelectCharacteristics = (selected: TCharacteristicsDTO) => {
        setTempCharacteristic({
            ...selected,
            id: selected.id,
            name: selected.name
        })
    }

    const handleSelectSupplier = (selected: TSupplierBody) => {
        setSuppiler(selected)
        formik.setFieldValue('supplierId', selected.id)
    }

    const handleSelectCategory = (selected: TCategoryBody) => {
        setCategory(selected)
        formik.setFieldValue('categoryId', selected.id)
    }

    const handleSelectBrand = (selected: TBrandBody) => {
        setBrand(selected)
        formik.setFieldValue('brandId', selected.id)
    }

    const handleSelectCollection = (selected: TCollectionBody) => {
        setCollection(selected)
        formik.setFieldValue('collectionId', selected.id)
    }

    const onClearSupplier = () => {
        formik.setFieldValue('supplierId', undefined)
        setSuppiler(undefined)
    }
    const onClearCategory = () => {
        formik.setFieldValue('categoryId', undefined)
        setCategory(undefined)
    }
    const onClearBrand = () => {
        formik.setFieldValue('brandId', undefined)
        setBrand(undefined)
    }
    const onClearCollection = () => {
        formik.setFieldValue('collectionId', undefined)
        setCollection(undefined)
    }

    const handleResetForm = () => {
        onClose()
        formik.resetForm()
        setCategory(product.category)
        setBrand(product.brand)
        setCollection(product.collection)
        setSuppiler(product.suppiler)
    }

    // ? funções do dialog
    const handleConfirmCharacteristic = () => {
        handleAddCharacteristics(tempCharacteristic)
    }

    const handleCancelCharacteristic = () => {
        setCharDialog(false)
        setErrorDialog('')
        setTempCharacteristic(initialCharacteristics)
    }

    // ? função para editar caracteristicas
    const handleEditCharacteristics = useCallback(
        (updatedCharacteristic: TCharacteristicsDTO) => {
            const newList = formik.values.characteristicsDTOList.map((char) =>
                char.id === updatedCharacteristic.id
                    ? { ...char, ...updatedCharacteristic }
                    : char
            )

            setEditCaracteristicModal(false)
            formik.setFieldValue('characteristicsDTOList', newList)
            setErrorDialog('')
            setTempCharacteristic(initialCharacteristics)

        },
        [formik]
    )

    const handleAddCharacteristics = useCallback(
        (newCharacteristic: TCharacteristicsDTO) => {
            let newChar: TCharacteristicsDTO = {
                id: uuidv4(),
                characteristcId: newCharacteristic.id,
                name: newCharacteristic.name,
                amount: newCharacteristic.amount,
                description: newCharacteristic.description
            }

            let newList = [...formik.values.characteristicsDTOList, newChar]

            formik.setFieldValue('characteristicsDTOList', newList)
            setCharDialog(false)
            setErrorDialog('')
            setTempCharacteristic(initialCharacteristics)

        },
        [formik]
    )

    // ? verifica os preços e atualiza os valores do lucro
    useEffect(() => {
        setPriceRelasePercent(calcularLucroRevenda(priceCost, resalePrice))
        setPriceTagPercent(calcularCustoEtiqueta(priceCost, priceTag))
    }, [priceCost, priceTag, resalePrice])

    // ? verifique se o produto foi alterado
    const hasChanges = useMemo(() => {
        // Verifica se os valores do formik são diferentes dos valores iniciais
        const productValuesChanged = !deepEqualProduct(
            formik.values,
            initialValues
        )

        // Verifica se as imagens foram alteradas
        const imagesChanged =
            initialImages.imageOne.url !==
            formik.values.productImages?.imageOne ||
            initialImages.imageTwo.url !==
            formik.values.productImages?.imageTwo ||
            initialImages.imageThree.url !==
            formik.values.productImages?.imageThree

        // Verifica se as características foram alteradas
        const characteristicsChanged = !deepEqualProduct(
            formik.values.characteristicsDTOList,
            characteristicsDTOList
        )

        // Retorna true se houver qualquer mudança no produto, imagens ou características
        return productValuesChanged || imagesChanged || characteristicsChanged
    }, [
        formik.values,
        initialValues,
        editedImages,
        initialImages,
        characteristicsDTOList
    ])

    const calcularLucro = (costPrice: number, profitPercentage: number): number => {
        return costPrice * (1 + profitPercentage / 100);
    };


    const handleProfitChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        type: 'revenda' | 'etiqueta'
    ) => {
        const cleanedValue = event.target.value.replace(/[^0-9.,]/g, '');
        const profitPercentage = parseFloat(cleanedValue);


        if (!isNaN(profitPercentage)) {
            if (type === 'revenda' && priceCost) {
                const updatedResalePrice = calcularLucro(priceCost, profitPercentage);
                formik.setFieldValue('resalePrice', updatedResalePrice); // Atualiza o valor no Formik
                setResalePrice(updatedResalePrice ); // Atualiza o estado local
            } else if (type === 'etiqueta' && resalePrice) {
                const updatedPriceTag = calcularLucro(resalePrice, profitPercentage);
                formik.setFieldValue('priceTag', updatedPriceTag);
                setPriceTag(updatedPriceTag);
            }
        }

        formik.handleChange(event);
    };

    const handleFocus = (event: any) => {
        event.target.select();
    };

    return (
        <>
            <DefaultDialog
                isOpen={charDialog}
                title={'Nova característica'}
                onCloseAction={() => handleCancelCharacteristic()}
                confirmAction={() => handleConfirmCharacteristic()}
                disabled={
                    tempCharacteristic.name === '' ||
                    tempCharacteristic.amount === 0 ||
                    tempCharacteristic.description === '' ||
                    tempCharacteristic.characteristcId === 0
                }
                body={
                    <AddCharacteristicContent
                        qtd={
                            formik.values.amount -
                            formik.values.characteristicsDTOList
                                .map((item) => item.amount)
                                .reduce((acc, curr) => acc + curr, 0)
                        }
                        error={errorDialog}
                        tempCharacteristic={tempCharacteristic}
                        setTempCharacteristic={setTempCharacteristic}
                    />
                }
            />

            <DefaultModal
                title="Editar Produto"
                isOpen={isOpen}
                onClose={handleResetForm}
                onOpen={onOpen}
                children={
                    <>
                        {loadingImages ? (
                            <MainContainer
                                sx={{
                                    height: '60svh',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <CircularProgress />
                            </MainContainer>
                        ) : (
                            <MainContainer>
                                <LeftContent>
                                    <ProductImages formik={formik} />
                                    <PriceContainer>
                                        <InputsPrice>
                                            <PriceTextField
                                                name="priceCost"
                                                label="Preço de Custo" 
                                                value={formik?.values?.priceCost?.toString() || ''}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    formik.handleChange(event);
                                                }}
                                                error={!!formik.errors.priceCost}
                                                helperText={formik.errors.priceCost}
                                                priceNumber={priceCost}
                                                setPriceNumber={setPriceCost}
                                                style={{ display: "flex", flex: 2 }}
                                            />

                                            <ProfitInPercentage>
                                                <Typography sx={{ fontSize: "1.1pc" }}>Lucro em %</Typography>
                                                <GenericTextField<number>
                                                    name="lucroRevenda"
                                                    label="Revenda"
                                                    inputMode="decimal"
                                                    value={formik.values.lucroRevenda || 0} // Fallback para 0 caso não tenha valor
                                                    onChange={(event) => handleProfitChange(event, 'revenda')}
                                                    style={{ display: "flex", width: "40%" }}
                                                    props={{ variant: "standard", disabled: !formik.values.priceCost, onFocus: handleFocus }} // Desabilita se o preço de custo não estiver preenchido
                                                />
                                            </ProfitInPercentage>
                                            <PriceTextField
                                                name="resalePrice"
                                                label="Preço de Revenda"
                                                value={formik?.values?.resalePrice?.toString() || ''}
                                                error={!!formik.errors.resalePrice}
                                                onChange={(event => formik.handleChange(event))}
                                                priceCost={priceCost}
                                                formik={formik}
                                                typeLucro={'lucroRevenda'}
                                                helperText={formik.errors.resalePrice}
                                                priceNumber={resalePrice}
                                                setPriceNumber={setResalePrice}
                                                style={{ display: "flex", flex: 2 }}
                                            />

                                            <ProfitInPercentage>
                                                <Typography sx={{ fontSize: "1.1pc" }}>Lucro em %</Typography>
                                                <GenericTextField<number>
                                                    name="lucroEtiqueta"
                                                    label="Etiqueta"
                                                    inputMode="decimal"
                                                    value={formik.values.lucroEtiqueta || 0} // Fallback para 0 caso não tenha valor
                                                    onChange={(event) => handleProfitChange(event, 'etiqueta')}
                                                    style={{ display: "flex", width: "40%" }}
                                                    props={{ variant: "standard", disabled: !formik.values.resalePrice, onFocus: handleFocus }} // Desabilita se o preço de custo não estiver preenchido
                                                />
                                            </ProfitInPercentage>

                                            <PriceTextField
                                                name="priceTag"
                                                label="Preço de Etiqueta"
                                                value={formik?.values?.priceTag?.toString() || ''}
                                                error={!!formik.errors.priceCost}
                                                onChange={(event => formik.handleChange(event))}
                                                resalePrice={resalePrice}
                                                formik={formik}
                                                typeLucro={'lucroEtiqueta'}
                                                helperText={formik.errors.priceCost}
                                                priceNumber={priceTag}
                                                setPriceNumber={setPriceTag}
                                                style={{ display: "flex", flex: 2 }}
                                            />
                                        </InputsPrice>
                                        <Button
                                            disabled={!hasChanges || isLoading}
                                            onClick={() =>
                                                formik.handleSubmit()
                                            }
                                            sx={{
                                                display: 'flex',
                                                flex: 1,
                                                marginTop: '0.3rem'
                                            }}
                                        >
                                            {isLoading ? <CircularProgress size={25} /> : 'EDITAR'}
                                        </Button>
                                    </PriceContainer>
                                </LeftContent>
                                <RightContent>
                                    <InfosContainer>
                                        <GenericTextField<string>
                                            error={!!formik.errors.name}
                                            helperText={formik.errors.name}
                                            small
                                            name={'name'}
                                            label={'Nome'}
                                            value={formik.values.name}
                                            props={{
                                                onChange: formik.handleChange
                                            }}
                                            style={{ width: '100%' }}
                                        />
                                        <RowAlign>
                                            <GenericTextField<number>
                                                error={!!formik.errors.amount}
                                                helperText={
                                                    formik.errors.amount
                                                }
                                                small
                                                name={'amount'}
                                                label={'Quantidade'}
                                                value={formik.values.characteristicsDTOList.length > 0 ?
                                                    totalCharacteristics : formik.values.amount}
                                                props={{
                                                    onChange:
                                                        formik.handleChange,
                                                    disabled: formik.values.characteristicsDTOList.length > 0,
                                                }}
                                                style={{
                                                    display: 'flex',
                                                    flex: 1
                                                }}
                                            />
                                            <AsyncAutoComplete
                                                selectedValue={suppiler}
                                                label="Procurar Fornecedor"
                                                handleOnSelect={
                                                    handleSelectSupplier
                                                }
                                                urlToSearch={SUPPLIER_LIST}
                                                sortField="createdAt"
                                                style={{
                                                    display: 'flex',
                                                    flex: 2
                                                }}
                                                error={formik.errors.supplierId}
                                                haveError={
                                                    !!formik.errors.supplierId
                                                }
                                                searchParamName="nameRepresentative"
                                                onClear={onClearSupplier}
                                            />
                                        </RowAlign>
                                        <AsyncAutoComplete
                                            selectedValue={category}
                                            label="Procurar Categoria"
                                            handleOnSelect={
                                                handleSelectCategory
                                            }
                                            urlToSearch={CATEGORY_LIST}
                                            sortField="name"
                                            error={formik.errors.categoryId}
                                            haveError={
                                                !!formik.errors.categoryId
                                            }
                                            searchParamName="name"
                                            onClear={onClearCategory}
                                            active="active"
                                        />
                                        <AsyncAutoComplete
                                            selectedValue={collection}
                                            label="Procurar Coleção"
                                            handleOnSelect={
                                                handleSelectCollection
                                            }
                                            urlToSearch={COLLECTION_LIST}
                                            sortField="name"
                                            error={formik.errors.collectionId}
                                            haveError={
                                                !!formik.errors.collectionId
                                            }
                                            searchParamName="name"
                                            onClear={onClearCollection}
                                            active="active"
                                        />
                                        <AsyncAutoComplete
                                            selectedValue={brand}
                                            label="Procurar Marca"
                                            handleOnSelect={handleSelectBrand}
                                            urlToSearch={BRAND_LIST}
                                            sortField="name"
                                            error={formik.errors.brandId}
                                            haveError={!!formik.errors.brandId}
                                            searchParamName="name"
                                            onClear={onClearBrand}
                                            active="active"
                                        />
                                    </InfosContainer>
                                    {/* tabela de caracteristicas */}
                                    <AddInfoContainer>
                                        <Button
                                            disabled={isLoading}
                                            variant="outlined"
                                            onClick={() => setCharDialog(true)}
                                            sx={{
                                                display: 'flex',
                                                width: '100%'
                                            }}
                                        >
                                            + ADICIONAR INFORMAÇÃO
                                        </Button>
                                        <Box
                                            sx={{
                                                overflowY: 'scroll',
                                                height: 200
                                            }}
                                        >
                                            <CharacteristicList
                                                rmv={removeCharacteristic}
                                                list={
                                                    formik.values
                                                        .characteristicsDTOList
                                                }
                                                edit={handleEditCharacteristic}
                                            />
                                        </Box>
                                    </AddInfoContainer>
                                </RightContent>
                                {editCaracteristicModal &&
                                    editCaracteristic && (
                                        <EditCharacteristics
                                            characteristic={editCaracteristic}
                                            handleEditCharacteristics={
                                                handleEditCharacteristics
                                            }
                                            tempCharacteristic={
                                                tempCharacteristic
                                            }
                                            formik={formik}
                                            isOpen={editCaracteristicModal}
                                            onClose={() =>
                                                setEditCaracteristicModal(false)
                                            }
                                            onOpen={() =>
                                                setEditCaracteristicModal(true)
                                            }
                                            handleSelectCharacteristic={
                                                handleSelectCharacteristics
                                            }
                                            error={errorDialog}
                                        />
                                    )}
                            </MainContainer>
                        )}
                    </>
                }
            />
        </>
    )
}

export default EditProductModal

// ? conteúdo do dialog de adicionar caractesristica
interface IAddCharacteristicContentProps {
    error: string
    qtd: number
    tempCharacteristic: TCharacteristicsDTO
    setTempCharacteristic: Dispatch<SetStateAction<TCharacteristicsDTO>>
}

const AddCharacteristicContent = ({
    setTempCharacteristic,
    tempCharacteristic,
    error,
    qtd
}: IAddCharacteristicContentProps) => {
    // ? função para alterar o estado da caracteristica por nome de atributo
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setTempCharacteristic((prevState) => ({
            ...prevState,
            [name]: name === 'amount' ? parseInt(value) : value
        }))
    }

    // ? função para selecionar o tipo da caracteristica
    const handleSelectCharacteristics = (selected: TCharacteristicsDTO) => {
        setTempCharacteristic({
            ...selected,
            description: '',
            amount: tempCharacteristic.amount
        })
    }

    return (
        <Box>
            <Typography fontWeight={'bold'} textAlign={'center'}>
                Quantidade do produto disponível: {qtd}
            </Typography>
            <AsyncAutoComplete
                label="Atribuir caracteristica"
                handleOnSelect={handleSelectCharacteristics}
                urlToSearch={CHARACTERISTIC_LIST}
                sortField="name"
                variant="standard"
                searchParamName="name"
            />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1,
                    alignItems: 'center'
                }}
            >
                <TextField
                    label="Descrição"
                    variant="standard"
                    name="description"
                    value={tempCharacteristic.description}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Quantidade"
                    variant="standard"
                    name="amount"
                    type="number"
                    defaultValue={tempCharacteristic.amount}
                    value={tempCharacteristic.amount ?? 0}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
            </Box>
            {error && (
                <FormHelperText
                    sx={{ margin: -0.5, padding: 0, textAlign: 'center' }}
                    error={true}
                >
                    {error}
                </FormHelperText>
            )}
        </Box>
    )
}
