import { SalesService } from "core/api/sales/salesService"
import { Order } from "../../models/table"
import { removeNonNumeric } from "core/utils/globalFunctions";

export const fecthSales = async (
    page: number,
    rowsPerPage: number,
    orderBy: string,
    order: Order,
    clientcpforcnpj?: string,
    status?: string,
    clientId?: string,
    salesCode?: string,
) => {
    return await SalesService.getFiltedSales({
        page: page,
        size: rowsPerPage,
        sort: orderBy + "," + order,
        clientcpforcnpj: clientcpforcnpj === "" ? undefined : removeNonNumeric(clientcpforcnpj),
        status: status === "" ? undefined : status,
        clientId: clientId === "" ? undefined : clientId,
        salesCode: salesCode === "" ? undefined : salesCode,
    })
};