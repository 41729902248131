import { TClientBody, TClientUpdate } from 'core/models/clientLBS'
import { useEffect, useMemo, useState } from 'react'
import { Box, Button, Divider, MenuItem, TextField } from '@mui/material'
import { Validations } from 'core/utils/validations'
import { getIn, useFormik } from 'formik'
import {
    formatarCEP,
    removeNonNumeric,
    statesOpitions
} from 'core/utils/globalFunctions'
import GenericTextField from 'app/components/genericTextField/GenericTextField'
import { ClientLbsService } from 'core/api/client/clientService'
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query'
import { IPage } from 'core/models/utils'
import DefaultModal from 'app/components/modals/defaultModal/defaultModal'

interface IEditClientModalProps {
    isOpen: boolean
    onClose: () => void
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    onOpen: () => void
    client: TClientBody
    refetch: (
        options?: RefetchOptions
    ) => Promise<QueryObserverResult<IPage<TClientBody> | undefined, Error>>
}

const EditClientModal = (props: IEditClientModalProps) => {
    const { isOpen, onClose, onOpen, client, setIsOpen } = props

    const [isValidating, setValidating] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const initialValues: TClientUpdate = {
        name: client?.name || '',
        cep: client?.address?.zipCode || '',
        uf: client?.address?.uf || '',
        neighborhood: client?.address?.neighborhood || '',
        city: client?.address?.city || '',
        cellPhone: client?.contacts?.cellPhone || '',
        cellPhone2: client?.contacts?.cellPhone2 || '',
        telephone: client?.contacts?.telephone || '',
        email: client?.contacts?.email || '',
        road: client?.address?.road || '',
        number: client?.address?.number || ''
    }

    const handleResetStates = () => {
        setIsLoading(false)
        formik.resetForm()
    }

    function getCepData(ev: any) {
        setValidating(true)
        const cep = ev?.replace(/[^0-9]/g, '')
        fetch(`https://viacep.com.br/ws/${cep}/json/`)
            .then((res) => res.json())
            .then((data) => {
                if (data.erro === true) {
                    formik.setFieldError('cep', 'CEP inválido')
                    setValidating(false)
                } else {
                    setValidating(false)
                    formik.setFieldValue('road', `${data.logradouro}`)
                    formik.setFieldValue('cep', formatarCEP(formik.values.cep))
                    formik.setFieldValue('city', `${data.localidade}`)
                    formik.setFieldValue('uf', `${data.uf}`)
                    formik.setFieldValue('neighborhood', `${data.bairro}`)
                    formik.setFieldTouched('cep', false)
                }
            })
            .catch((err) => {
                formik.setFieldError('cep', 'CEP inválido')
                setValidating(false)
            })
    }

    const callEditClient = async (updatedUser: TClientUpdate) => {
        setIsLoading(true)

        let cleanedClientUser: TClientUpdate = {
            name: updatedUser.name,
            cep: removeNonNumeric(updatedUser.cep) ?? '',
            uf: updatedUser.uf,
            city: updatedUser.city,
            neighborhood: updatedUser.neighborhood,
            email: updatedUser.email,
            cellPhone: removeNonNumeric(updatedUser.cellPhone) ?? "",
            cellPhone2: removeNonNumeric(updatedUser.cellPhone2) ?? "",
            telephone: removeNonNumeric(updatedUser.telephone) ?? '',
            road: updatedUser.road ?? '',
            number: updatedUser.number ?? ''
        }

        if (client?.id) {
            ClientLbsService.updateClient(
                cleanedClientUser,
                String(client.id)
            ).then((resp) => {
                if (resp.status === 200) {
                    handleResetStates()
                    setIsOpen(false)
                    props.refetch()
                    setIsLoading(false)
                }
                setIsLoading(false)
            })
            setIsLoading(false)
        }
        setIsLoading(false)
    }

    const formik = useFormik({
        initialValues,
        validateOnBlur: false,
        validationSchema: Validations.EditClientShema,
        validateOnChange: false,
        onSubmit: async (values, { setSubmitting }) => {
            await callEditClient(values)
            setSubmitting(false)
        }
    })

    const hasChanges = useMemo(() => {
        return Object.keys(initialValues).some(
            (key) =>
                formik.values[key as keyof TClientUpdate] !==
                initialValues[key as keyof TClientUpdate]
        )
    }, [formik.values, initialValues])
    

    return (
        <DefaultModal
            title="Editar cliente"
            isOpen={isOpen}
            onClose={onClose}
            onOpen={onOpen}
            children={
                <>
                    <Box
                        sx={{
                            gap: '0.7rem',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'start',
                            padding: '1rem',
                            width: '500px'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '1rem',
                                flex: 1
                            }}
                        >
                            <GenericTextField<string>
                                error={!!formik.errors.name}
                                helperText={formik.errors.name}
                                small
                                name={'name'}
                                label={'Nome *'}
                                value={formik.values.name}
                                props={{
                                    onChange: formik.handleChange
                                }}
                                onChangeManual={(e: string) =>
                                    formik.setFieldValue('name', e)
                                }
                            />
                            <GenericTextField<string>
                                error={!!formik.errors.cellPhone}
                                helperText={formik.errors.cellPhone}
                                small
                                name={'cellPhone'}
                                label={'Celular primário *'}
                                value={formik.values.cellPhone}
                                props={{
                                    onChange: formik.handleChange
                                }}
                                onChangeManual={(e: string) =>
                                    formik.setFieldValue('cellPhone', e)
                                }
                            />
                            <GenericTextField<string>
                                error={!!formik.errors.cellPhone2}
                                helperText={formik.errors.cellPhone2}
                                small
                                name={'cellPhone2'}
                                label={'Celular secundário'}
                                value={formik.values.cellPhone2}
                                props={{
                                    onChange: formik.handleChange
                                }}
                                onChangeManual={(e: string) =>
                                    formik.setFieldValue('cellPhone2', e)
                                }
                            />
                            <GenericTextField<string>
                                error={!!formik.errors.telephone}
                                helperText={formik.errors.telephone}
                                small
                                name={'telephone'}
                                label={'Telefone'}
                                value={formik.values.telephone}
                                props={{
                                    onChange: formik.handleChange
                                }}
                                onChangeManual={(e: string) =>
                                    formik.setFieldValue('telephone', e)
                                }
                            />
                            <GenericTextField<string>
                                error={!!formik.errors.email}
                                helperText={formik.errors.email}
                                small
                                name={'email'}
                                label={'Email'}
                                value={formik.values.email}
                                props={{
                                    onChange: formik.handleChange
                                }}
                                onChangeManual={(e: string) =>
                                    formik.setFieldValue('email', e)
                                }
                            />
                        </Box>
                        <Divider orientation="vertical" flexItem />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '1rem',
                                flex: 1
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '0.5rem'
                                }}
                            >
                                <GenericTextField<string>
                                    error={!!formik.errors.cep}
                                    helperText={formik.errors.cep}
                                    small
                                    name={'cep'}
                                    label={'CEP *'}
                                    value={formik.values.cep}
                                    props={{
                                        onChange: formik.handleChange
                                    }}
                                    onBlur={() => getCepData(formik.values.cep)}
                                    style={{ flex: 2 }}
                                    onChangeManual={(e: string) =>
                                        formik.setFieldValue('cep', e)
                                    }
                                />
                                <TextField
                                    value={formik.values.uf}
                                    onChange={(e) =>
                                        formik.setFieldValue(
                                            'uf',
                                            e.target.value
                                        )
                                    }
                                    disabled={isValidating}
                                    id="outlined-select-state"
                                    margin="none"
                                    select
                                    label="UF *"
                                    size="small"
                                    name="uf"
                                    style={{ flex: 1 }}
                                    error={Boolean(getIn(formik.errors, 'uf'))}
                                    helperText={getIn(formik.errors, 'uf')}
                                    SelectProps={{
                                        MenuProps: {
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 100
                                                }
                                            }
                                        }
                                    }}
                                    FormHelperTextProps={{
                                        style: {
                                            margin: '1px 10px -5px '
                                        }
                                    }}
                                >
                                    {statesOpitions.map((option) => (
                                        <MenuItem
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>

                            <GenericTextField<string>
                                error={!!formik.errors.neighborhood}
                                helperText={formik.errors.neighborhood}
                                small
                                name={'neighborhood'}
                                label={'Bairro *'}
                                value={formik.values.neighborhood}
                                props={{
                                    onChange: formik.handleChange
                                }}
                                onChangeManual={(e: string) =>
                                    formik.setFieldValue('neighborhood', e)
                                }
                            />
                            <GenericTextField<string>
                                error={!!formik.errors.city}
                                helperText={formik.errors.city}
                                small
                                name={'city'}
                                label={'Cidade *'}
                                value={formik.values.city}
                                props={{
                                    onChange: formik.handleChange
                                }}
                                onChangeManual={(e: string) =>
                                    formik.setFieldValue('city', e)
                                }
                            />
                             <GenericTextField<string>
                                error={!!formik.errors.road}
                                helperText={formik.errors.road}
                                small
                                name={'road'}
                                label={'Rua *'}
                                value={formik.values.road}
                                props={{
                                    onChange: formik.handleChange
                                }}
                                onChangeManual={(e: string) =>
                                    formik.setFieldValue('road', e)
                                }
                            />
                            <GenericTextField<string>
                                error={!!formik.errors.number}
                                helperText={formik.errors.number}
                                small
                                name={'number'}
                                label={'Número *'}
                                value={formik.values.number}
                                props={{
                                    onChange: formik.handleChange
                                }}
                                onChangeManual={(e: string) =>
                                    formik.setFieldValue('number', e)
                                }
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            gap: ' 1rem',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            paddingBottom: '0.4rem'
                        }}
                    >
                        <Button
                            disabled={isLoading || isValidating}
                            onClick={() => setIsOpen(false)}
                            variant="outlined"
                        >
                            Voltar
                        </Button>
                        <Button
                            disabled={!hasChanges || isLoading || isValidating}
                            onClick={() => formik.handleSubmit()}
                        >
                            Editar
                        </Button>
                    </Box>
                </>
            }
        />
    )
}

export default EditClientModal
